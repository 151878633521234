* {
  scroll-behavior: smooth;
}

/**
Styling below is copied from Cludo css 
*/
#cludo-404.override h3 {
  display: none;
}

#cludo-404.override ul {
  width: 100%;
}

.powered-by {
  display: none;
}

#cludo-404.override ul li a:hover {
  color: #4a6283 !important;
}

#cludo-404.override ul li a {
  box-sizing: border-box;
  border-radius: .625rem;
  position: relative;
  background-color: #fff;
  color: #000;
  padding: 2rem 2.7rem 1rem;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  transition: all .3s cubic-bezier(.65, 0, .076, 1);
}

#cludo-404.override ul li h4 {
  word-break: break-word;
  font-family: Montserrat, sans-serif;
  font-size: 1.5em;
  line-height: 122%;
  font-weight: 600;
  transition: all .3s cubic-bezier(.65, 0, .076, 1);
  display: flex;
}

#cludo-404.override ul li {
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .05), rgba(0, 0, 0, .05) 0 1.25rem 1.5625rem -.3125rem, rgba(0, 0, 0, .04) 0 .625rem .625rem -.3125rem;
  margin-bottom: 30px;
  display: flex;
  box-sizing: border-box;
  border-radius: .625rem;
  position: relative;
  background-color: #fff;
  color: #000;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  transition: all .3s cubic-bezier(.65, 0, .076, 1);
}

.override.loaded {
  width: 100%;
}

#cludo-404 .cludo-result-item-title + .cludo-result-item-path {
  word-break: break-all;
  padding: 0rem 2.7rem 2rem 2.7rem;
}

/* Mobile styles */
@media only screen and (max-width: 375px) {
  #cludo-404.override ul li a, 
  #cludo-404.override ul li {
    padding: 0.8rem 0.5rem;
    width: 100%;
  }

  #cludo-404.override ul li h4 {
    font-size: 1.2em;
    line-height: 1.2;
    padding: 0.3rem 0;
  }
}
